import { SENDTOBPCS_PERMISSION, SENDTOSCALE_PERMISSION, SENDCERTIFICATES_PERMISSION, SENDPADDOCKREPORTS_PERMISSION, GROWERS_FINANCEPROCESSING } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "p-5"
  }, [_c("a-row", {
    attrs: {
      "gutter": 24,
      "type": "flex"
    }
  }, [_c("a-col", {
    staticClass: "mb-4",
    attrs: {
      "span": 12
    }
  }, [_c("a-card", {
    staticClass: "finance-non-padding h-100"
  }, [_c("div", {
    staticClass: "px-4 pt-4"
  }, [_c("span", {
    staticClass: "h6 neutral-10--text"
  }, [_vm._v("BPCS Update")]), _c("p", {
    staticClass: "b8 pt-4 m-0 neutral-8--text"
  }, [_vm._v(" Running this update will refresh Crops, Crop Seasons, Pricing Crops, Cultivars. BPCS Grades (not Prices), Attributes and Deductions. "), _c("br"), _vm._v("Choose a crop to update or select All Crops to update all crops. ")]), _c("a-row", {
    staticClass: "mt-2 align-items-center",
    attrs: {
      "gutter": 24,
      "type": "flex"
    }
  }, [_c("a-col", {
    attrs: {
      "span": 16
    }
  }, [_c("select-input", {
    attrs: {
      "form-item": "",
      "label": "Crop Code",
      "reference": "grower.common.crops-for-finance",
      "source": "id",
      "disabled": !_vm.hasSendToBPCSPermission || _vm.isDisableCropCodeSelect,
      "source-label": _vm.generateCropLabel,
      "value": _vm.cropCode
    },
    on: {
      "change": _vm.onCropCodeChange
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 8
    }
  }, [_c("checkbox-input", {
    attrs: {
      "label": "All Crops",
      "disabled": !_vm.hasSendToBPCSPermission
    },
    on: {
      "change": _vm.onChangeAllCrops
    }
  })], 1)], 1)], 1), _c("div", {
    staticClass: "ant-modal-footer"
  }, [_c("a-icon", {
    staticClass: "mr-2",
    attrs: {
      "type": "info-circle",
      "theme": "twoTone"
    },
    on: {
      "click": _vm.infoBPCS
    }
  }), _c("a-button", {
    attrs: {
      "type": "default",
      "disabled": !_vm.hasSendToBPCSPermission
    },
    on: {
      "click": function($event) {
        return _vm.onSubmitBPCSUpdateOvernight();
      }
    }
  }, [_c("span", [_vm._v("BPCS Update Overnight")])]), _c("a-button", {
    attrs: {
      "type": "primary",
      "disabled": !_vm.hasSendToBPCSPermission
    },
    on: {
      "click": function($event) {
        return _vm.onSubmitBPCSUpdate();
      }
    }
  }, [_c("span", [_vm._v("BPCS Update")])])], 1)])], 1), _c("a-col", {
    staticClass: "mb-4",
    attrs: {
      "span": 12
    }
  }, [_c("a-card", {
    staticClass: "finance-non-padding h-100 custom-card-body"
  }, [_c("div", {
    staticClass: "px-4 pt-4"
  }, [_c("span", {
    staticClass: "h6 neutral-10--text"
  }, [_vm._v("Send to Scale")]), _c("a-row", {
    attrs: {
      "gutter": 24,
      "type": "flex"
    }
  }, [_c("a-col", {
    staticClass: "pt-4",
    attrs: {
      "span": 24
    }
  }, [_c("span", {
    staticClass: "b7 neutral-8--text"
  }, [_vm._v("File to be downloaded:")])]), _c("a-col", {
    staticClass: "py-1 neutral-10--text",
    attrs: {
      "span": 12
    }
  }, [_vm._v("Operator")]), _c("a-col", {
    staticClass: "py-1 neutral-10--text",
    attrs: {
      "span": 12
    }
  }, [_vm._v("Crops")]), _c("a-col", {
    staticClass: "py-1 neutral-10--text",
    attrs: {
      "span": 12
    }
  }, [_vm._v("Sample Types")]), _c("a-col", {
    staticClass: "py-1 neutral-10--text",
    attrs: {
      "span": 12
    }
  }, [_vm._v("Deductions")]), _c("a-col", {
    staticClass: "py-1 neutral-10--text",
    attrs: {
      "span": 12
    }
  }, [_vm._v("Attributes")]), _c("a-col", {
    staticClass: "py-1 neutral-10--text",
    attrs: {
      "span": 12
    }
  }, [_vm._v("Grades")])], 1)], 1), _c("div", {
    staticClass: "ant-modal-footer"
  }, [_c("a-button", {
    attrs: {
      "type": "primary",
      "disabled": !_vm.hasSendToScalePermission
    },
    on: {
      "click": function($event) {
        return _vm.onSumbitSendToScale();
      }
    }
  }, [_c("span", [_vm._v("Send to Scale")])])], 1)])], 1)], 1), _c("a-row", {
    attrs: {
      "gutter": 24,
      "type": "flex"
    }
  }, [_c("a-col", {
    attrs: {
      "span": 24
    }
  }, [_c("a-card", {
    staticClass: "finance-non-padding h-100"
  }, [_c("div", {
    staticClass: "px-4 pt-4"
  }, [_c("span", {
    staticClass: "h6 neutral-10--text"
  }, [_vm._v(" Email Reports "), _c("a-icon", {
    staticClass: "ml-2",
    attrs: {
      "type": "info-circle",
      "theme": "twoTone"
    },
    on: {
      "click": _vm.infoEmailReports
    }
  })], 1), _c("a-row", {
    staticClass: "flex-wrap align-items-center",
    attrs: {
      "gutter": 24,
      "type": "flex"
    }
  }, [_c("a-col", {
    attrs: {
      "span": 6
    }
  }, [_c("select-input", {
    attrs: {
      "reference": "grower.common.seasons",
      "form-item": "",
      "label": "Season",
      "source": "season",
      "value": _vm.season,
      "disabled": !_vm.hasSendCertificatesPermission && !_vm.hasSendPaddockReportPermission
    },
    on: {
      "change": _vm.onSeasonChange
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 6
    }
  }, [_c("select-input", {
    attrs: {
      "form-item": "",
      "label": "Crop",
      "reference": "grower.common.crops-for-finance",
      "source": "id",
      "source-label": _vm.generateCropLabel,
      "value": _vm.cropId,
      "disabled": !_vm.hasSendCertificatesPermission && !_vm.hasSendPaddockReportPermission
    },
    on: {
      "change": _vm.onCropChange
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 6
    }
  }, [_c("select-input", {
    attrs: {
      "form-item": "",
      "label": "Grower",
      "queries": _vm.growerQueries,
      "reference": "grower.common.growers-contract",
      "source": "id",
      "source-label": "name",
      "value": _vm.growerId,
      "disabled": !_vm.hasSendCertificatesPermission && !_vm.hasSendPaddockReportPermission
    },
    on: {
      "change": _vm.onGrowerChange
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 6
    }
  }, [_c("select-input", {
    attrs: {
      "form-item": "",
      "label": "Contract",
      "queries": _vm.contractQueries,
      "reference": "grower.common.contracts-by-grower-pricingcrop",
      "source": "id",
      "source-label": "code",
      "value": _vm.contractId,
      "disabled": !_vm.hasSendCertificatesPermission && !_vm.hasSendPaddockReportPermission
    },
    on: {
      "change": _vm.onContractChange
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 12
    }
  }, [_c("date-range-picker", {
    attrs: {
      "form-item": "",
      "label": "Accounting Date",
      "disabled": !_vm.hasSendCertificatesPermission && !_vm.hasSendPaddockReportPermission
    },
    on: {
      "change": _vm.onAccountingDateChange
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 6
    }
  }, [_c("select-input", {
    attrs: {
      "form-item": "",
      "label": "Paddock",
      "queries": _vm.paddockQueries,
      "reference": "grower.common.paddocks-SGCC",
      "source": "id",
      "source-label": "code",
      "value": _vm.paddockId,
      "disabled": !_vm.hasSendCertificatesPermission && !_vm.hasSendPaddockReportPermission
    },
    on: {
      "change": _vm.onPaddockChange
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 6
    }
  }, [_c("checkbox-input", {
    attrs: {
      "label": "Test Run",
      "value": _vm.testRun,
      "disabled": !_vm.hasSendCertificatesPermission && !_vm.hasSendPaddockReportPermission
    },
    on: {
      "change": _vm.onTestRunChange
    }
  })], 1)], 1)], 1), _c("div", {
    staticClass: "ant-modal-footer"
  }, [_c("a-button", {
    attrs: {
      "type": "default",
      "disabled": !_vm.hasSendCertificatesPermission
    },
    on: {
      "click": function($event) {
        return _vm.onSubmitSendCertificates();
      }
    }
  }, [_c("span", [_vm._v("Send Certificates")])]), _c("a-button", {
    attrs: {
      "type": "primary",
      "disabled": !_vm.hasSendPaddockReportPermission
    },
    on: {
      "click": function($event) {
        return _vm.onSubmitSendPaddockReport();
      }
    }
  }, [_c("span", [_vm._v("Send Paddock Report")])])], 1)])], 1)], 1)], 1);
};
var staticRenderFns$1 = [];
var FinanceProcessing_vue_vue_type_style_index_0_lang = "";
const __vue2_script$1 = {
  name: "FinanceProcessing",
  inject: ["resourceProps", "can"],
  data() {
    const [financeProps] = this.resourceProps;
    return {
      contractId: "",
      cropCode: "",
      cropId: "All Crops",
      financeProps,
      growerId: "",
      isDisableCropCodeSelect: false,
      isSubmitting: false,
      paddockId: "",
      season: "",
      sDate: "",
      eDate: "",
      testRun: false
    };
  },
  computed: {
    contractQueries() {
      if (!this.cropId && !this.growerId)
        return null;
      const queries = [];
      if (this.cropId)
        queries.push(["cropId", this.cropId]);
      if (this.growerId)
        queries.push(["growerId", this.growerId]);
      return queries;
    },
    growerQueries() {
      if (!this.cropId)
        return null;
      return [["cropId", this.cropId]];
    },
    hasSendToBPCSPermission() {
      return this.can(SENDTOBPCS_PERMISSION);
    },
    hasSendToScalePermission() {
      return this.can(SENDTOSCALE_PERMISSION);
    },
    hasSendCertificatesPermission() {
      return this.can(SENDCERTIFICATES_PERMISSION);
    },
    hasSendPaddockReportPermission() {
      return this.can(SENDPADDOCKREPORTS_PERMISSION);
    },
    paddockQueries() {
      if (!this.season && !this.cropId && !this.growerId && !this.contractId)
        return null;
      const queries = [];
      if (this.season)
        queries.push(["season", this.season]);
      if (this.cropId)
        queries.push(["cropId", this.cropId]);
      if (this.growerId)
        queries.push(["growerId", this.growerId]);
      if (this.contractId)
        queries.push(["contractId", this.contractId]);
      return queries;
    }
  },
  created() {
    this.storeValue("testRun", false);
  },
  methods: {
    generateCropLabel(option) {
      return `${option.code} ${option.name}`;
    },
    infoBPCS() {
      const h = this.$createElement;
      this.$info({
        title: "BPCS Update Overnight",
        content: h("div", {}, [
          h("p", "Note:"),
          h("p", "Running this update will refresh Growers, Contracts, Paddocks, Containers, Trucks, Grade Prices and Grower Grade Prices. This job normally runs overnight each night so should only be started if last minute changes are urgently required.")
        ]),
        onOk() {
        }
      });
    },
    infoEmailReports() {
      const h = this.$createElement;
      this.$info({
        title: "Email Reports",
        content: h("div", {}, [
          h("p", "Note:"),
          h("p", "- Season. Crop and Paddock inputs are relevant only when sending Paddock reports."),
          h("p", "- Date range is required for both reports."),
          h("p", "- Contract is filtered by Grower if entered but not Crop. Paddock is filtered by Season, Grower, Crop and Contract."),
          h("p", "- Test Run flag when set to Yes results in the email messages being sent to the report initiator instead of to the Contract Email Address.")
        ]),
        onOk() {
        }
      });
    },
    storeValue(resourceKey, value) {
      this.financeProps.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    onChangeAllCrops(value) {
      this.isDisableCropCodeSelect = value;
      this.cropCode = "";
      this.storeValue("cropCode", "");
    },
    onCropCodeChange(value) {
      this.cropCode = value;
      this.storeValue("cropCode", value);
      this.storeValue("overnight", false);
    },
    onSeasonChange(value) {
      this.season = value;
      this.storeValue("season", value);
    },
    onGrowerChange(value) {
      this.growerId = value;
      this.storeValue("growerId", value);
    },
    onCropChange(value) {
      this.cropId = value;
      this.storeValue("cropId", value);
    },
    onContractChange(value) {
      this.contractId = value;
      this.storeValue("contractId", value);
    },
    onAccountingDateChange(value) {
      this.sDate = value.startDate;
      this.eDate = value.endDate;
      this.storeValue("SDate", value.startDate);
      this.storeValue("EDate", value.endDate);
    },
    onPaddockChange(value) {
      this.paddockId = value;
      this.storeValue("paddockId", value);
    },
    onTestRunChange(value) {
      this.testRun = value;
      this.storeValue("testRun", value);
    },
    async onSubmitBPCSUpdate() {
      this.isSubmitting = true;
      this.storeValue("overnight", false);
      try {
        const { apiUrl: apiUrl2 } = this.financeProps;
        const url = `${apiUrl2}/grower/finances/update-bpcs`;
        await this.financeProps.crud.submitEntity("create", url);
      } finally {
        this.isSubmitting = false;
      }
    },
    async onSubmitBPCSUpdateOvernight() {
      this.isSubmitting = true;
      this.storeValue("overnight", true);
      try {
        const { apiUrl: apiUrl2 } = this.financeProps;
        const url = `${apiUrl2}/grower/finances/update-bpcs`;
        await this.financeProps.crud.submitEntity("create", url);
      } finally {
        this.isSubmitting = false;
      }
    },
    async onSubmitSendPaddockReport() {
      this.isSubmitting = true;
      try {
        const { apiUrl: apiUrl2 } = this.financeProps;
        const url = `${apiUrl2}/grower/finances/send-paddock-report`;
        await this.financeProps.crud.submitEntity("create", url);
      } finally {
        this.isSubmitting = false;
      }
    },
    async onSubmitSendCertificates() {
      this.isSubmitting = true;
      try {
        const { apiUrl: apiUrl2 } = this.financeProps;
        const url = `${apiUrl2}/grower/finances/send-certificates`;
        await this.financeProps.crud.submitEntity("create", url);
      } finally {
        this.isSubmitting = false;
      }
    },
    onSumbitSendToScale() {
      const { apiUrl: apiUrl2 } = this.financeProps;
      const url = `${apiUrl2}/grower/finances/send-to-scale`;
      const { data } = this.axios.get(url);
      if (!data) {
        this.$notification.success({ message: "Send Success" });
      } else {
        this.$notification.error({ message: "Send Fail" });
      }
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var FinanceProcessing = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", {
    attrs: {
      "items": _vm.menuItems
    }
  }), _c("div", {
    staticClass: "h5 pl-5 pb-4 pt-2 barcode-heading"
  }, [_vm._v("Finance Processing")]), _c("resource", {
    attrs: {
      "name": "grower.common.seasons",
      "resource-id-name": "season",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.common.crops-for-finance",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.common.growers-contract",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.common.contracts-by-grower-pricingcrop",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.common.paddocks-SGCC",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": ["grower.finance"],
      "api-url": _vm.apiUrl,
      "page": _vm.page
    }
  }, [_c("finance-processing")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    FinanceProcessing
  },
  data() {
    return {
      apiUrl,
      page: GROWERS_FINANCEPROCESSING,
      menuItems: [
        {
          key: "financeprocessing",
          title: "Finance Processing",
          path: ""
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
